import type { RootState } from "@talktype/store";
import type { EditorProps } from "@talktype/ui/src/Editor/Editor";

import { connect } from "react-redux";

import {
  createEditor,
  editorChanged,
  editorClicked,
  editorCopy,
  editorCut,
  editorKeyDown,
  editorKeyUp,
  editorLoaded,
  editorPaste,
  editorUnloaded,
  selectEditorInitialValue,
} from "@talktype/editor";
import { selectDocumentStyle } from "@talktype/preferences";
import { selectInProgress } from "@talktype/results/src/state/selectors";
import { Editor as Component } from "@talktype/ui/src/Editor/Editor";

type StateProps = Pick<
  EditorProps,
  "createEditor" | "style" | "inProgress" | "initialValue"
>;

type DispatchProps = Pick<
  EditorProps,
  | "onLoad"
  | "onUnload"
  | "onChange"
  | "onClick"
  | "onKeyDown"
  | "onKeyUp"
  | "onCut"
  | "onCopy"
  | "onPaste"
>;

const mapStateToProps = (state: RootState): StateProps => ({
  createEditor,
  style: selectDocumentStyle(state),
  inProgress: selectInProgress(state),
  initialValue: selectEditorInitialValue(state),
});

const mapDispatchToProps: DispatchProps = {
  onLoad: editorLoaded,
  onUnload: editorUnloaded,
  onChange: editorChanged,
  onClick: editorClicked,
  onKeyDown: editorKeyDown,
  onKeyUp: editorKeyUp,
  onCut: editorCut,
  onCopy: editorCopy,
  onPaste: editorPaste,
};

export const Editor = connect(mapStateToProps, mapDispatchToProps)(Component);
