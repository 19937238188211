import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { takeEvery, call, put } from "redux-saga/effects";
import { Transforms } from "slate";

import { focusEditor } from "@carescribe/slate/src/utils/focusEditor";
import { getRangeOfLastInstance } from "@carescribe/slate/src/utils/range/getRangeOfLastInstance";

import { requestSelectPhrase, selectedPhrase } from "../actions";
import { getEditor } from "../utils";

/**
 * Handles requests to modify editor selection:
 *
 * - Finds the last instance of and selects the target text
 */
export const manageSelectPhrase = function* (): SagaIterator<void> {
  yield takeEvery(requestSelectPhrase, function* ({ payload: target }) {
    const editor: SagaReturnType<typeof getEditor> = yield call(getEditor, {
      documentUUID: null,
    });

    if (!editor) {
      yield put(selectedPhrase(false));
      return;
    }

    // Find last instance of target text
    const matchRange: SagaReturnType<typeof getRangeOfLastInstance> =
      yield call(getRangeOfLastInstance, editor, target);

    // If a match was found, select it
    if (matchRange) {
      yield call(focusEditor, editor);
      yield call(Transforms.select, editor, matchRange);
      yield put(selectedPhrase(true));
    } else {
      yield put(selectedPhrase(false));
    }
  });
};
