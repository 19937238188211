import type { ReactElement, ReactNode } from "react";

import styles from "./item.module.scss";
import { IconContainer } from "../../IconContainer";

export type ContentProps = {
  label: string;
  Icon: ReactNode;
};

export const Content = ({ label, Icon }: ContentProps): ReactElement => (
  <>
    <IconContainer className={styles.icon}>{Icon}</IconContainer>

    {label}
  </>
);
