import type { DrawerProps } from "@talktype/ui/src/Drawer";

import { connect } from "react-redux";

import { Drawer as Component } from "@talktype/ui/src/Drawer";

import { Library } from "./Library";

const mapStateToProps = (): DrawerProps => ({
  label: "Library",
  children: <Library />,
  isOpen: true,
  width: "regular",
});

export const LibraryDrawer = connect(mapStateToProps, null)(Component);
