import type { Leaf, Title } from "@talktype/types";

import { textNode } from "./textNode";

export const titleNode = (
  placeholder: Title["placeholder"],
  children?: Leaf | Leaf[]
): Title => ({
  type: "title",
  placeholder,
  children: Array.isArray(children) ? children : [children ?? textNode()],
});
