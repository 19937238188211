import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, takeEvery } from "redux-saga/effects";
import { Transforms } from "slate";

import { preventDefault } from "@carescribe/utilities/src/sagas/utils";

import { defaultBlock } from "@talktype/config/src/defaultBlock";
import { getBlockTypeAtSelection } from "@talktype/utilities/src/slate/getBlockTypeAtSelection";

import { insertParagraph } from "../../utils/insertHandlers";
import {
  editorBlockSet,
  editorBlockToggled,
  editorKeyDown,
  requestSetBlock,
  requestToggleBlock,
} from "../actions";
import { getEditor } from "../utils/getEditor";

export const setUpBlocks = function* (): SagaIterator<void> {
  yield takeEvery(requestToggleBlock, function* ({ payload: block }) {
    const editor: SagaReturnType<typeof getEditor> = yield call(getEditor, {
      documentUUID: null,
    });

    if (!editor) {
      return;
    }

    const blockType: SagaReturnType<typeof getBlockTypeAtSelection> =
      yield call(getBlockTypeAtSelection, editor);

    const type = blockType === defaultBlock ? block : defaultBlock;

    yield call(Transforms.setNodes, editor, { type });

    const isEnabled = type === block;
    yield put(editorBlockToggled(isEnabled));
  });

  yield takeEvery(requestSetBlock, function* ({ payload: type }) {
    const editor: SagaReturnType<typeof getEditor> = yield call(getEditor, {
      documentUUID: null,
    });

    if (editor) {
      yield call(Transforms.setNodes, editor, { type });
    }

    yield put(editorBlockSet());
  });

  /**
   * Switch back to a paragraph when the Enter key is pressed
   */
  yield takeEvery(editorKeyDown, function* ({ payload: event }) {
    const editor: SagaReturnType<typeof getEditor> = yield call(getEditor, {
      documentUUID: null,
    });

    if (!editor) {
      return;
    }

    const isEnterKeyPress = event.key === "Enter" && !event.shiftKey;
    if (!isEnterKeyPress) {
      return;
    }

    yield call(preventDefault, event);
    yield call(insertParagraph, editor);
  });
};
