import type { HTMLAttributes, ReactElement } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";

import styles from "./listItem.module.scss";

export type ListProps = HTMLAttributes<HTMLLIElement>;

export const ListItem = ({
  className,
  children,
  ...listItemProps
}: ListProps): ReactElement => (
  <li className={classNames(className, styles.listItem)} {...listItemProps}>
    {children}
  </li>
);
