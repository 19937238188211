import type { ReactElement, ReactNode } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import styles from "./header.module.scss";

export type HeaderProps = {
  style: "default" | "transparent";
  children: ReactNode;
};

export const Header = ({ style, children }: HeaderProps): ReactElement => (
  <header
    className={classNames(
      styles.header,
      styles[style],
      createSelectorClassName("header", "section")
    )}
  >
    {children}
  </header>
);
