import type {
  DispatchProps,
  StateProps,
} from "@carescribe/types/src/utils/connect";
import type { PopOverButtonProps } from "@carescribe/ui";
import type { AppDispatch, RootState } from "@talktype/store";
import type { MainMenuProps } from "@talktype/ui/src/MainMenu";
import type { ReactNode } from "react";

import { connect } from "react-redux";

import { noOp } from "@carescribe/utilities/src/fp";
import { getAppContext } from "@carescribe/utilities/src/getAppContext";

import {
  selectChameleonIsEnabled,
  selectLibrarySystemIsEnabled,
} from "@talktype/feature-flags/src/reducers/selectors";
import { selectPlatform } from "@talktype/system/src/reducers/system/selectors/selectPlatform";
import { LogoActionButton } from "@talktype/ui/src/LogoActionButton";
import { MainMenu as Component } from "@talktype/ui/src/MainMenu";
import { MainMenuTrigger } from "@talktype/ui/src/MainMenuTrigger";
import { selectMe } from "@talktype/user/src/reducer/selectors/selectMe";
import { selectTalkTypeDashboardUrl } from "@talktype/user/src/reducer/selectors/selectTalkTypeDashboardUrl";
import { requestLogout } from "@talktype/user/src/sagas/actions";

import { AutoDateFormattingField } from "./AutoDateFormattingField";
import { DictationLanguageField } from "./DictationLanguageField";
import { FilterProfanityField } from "./FilterProfanityField";
import { NumberFormattingField } from "./NumberFormattingField";
import { PinTalkTypeToFrontField } from "./PinTalkTypeToFrontField";
import { ThemeField } from "./ThemeField";
import { VersionDescription } from "./VersionDescription";

const mapStateToProps = (state: RootState): StateProps<MainMenuProps> => {
  const librarySystemIsEnabled = selectLibrarySystemIsEnabled(state);
  const user = librarySystemIsEnabled ? selectMe(state) : null;

  return {
    user,
    preferencesFields: (
      <>
        <ThemeField />
        <DictationLanguageField />
        <AutoDateFormattingField />
        <NumberFormattingField />
        <FilterProfanityField />
        {getAppContext() === "desktop" && <PinTalkTypeToFrontField />}
        <VersionDescription />
      </>
    ),
    renderTrigger: (buttonProps: PopOverButtonProps): ReactNode =>
      librarySystemIsEnabled ? (
        <MainMenuTrigger
          initials={user ? user.initials : "TT"}
          {...buttonProps}
        />
      ) : (
        <LogoActionButton {...buttonProps} />
      ),
    dashboardUrl: selectTalkTypeDashboardUrl(state),
    canSubmitFeedback: selectChameleonIsEnabled(state),
    platform: selectPlatform(state),
  };
};

const mapDispatchToProps = (
  dispatch: AppDispatch
): DispatchProps<MainMenuProps> => ({
  logOut: () => dispatch(requestLogout),

  /**
   * A hook for Chameleon to latch onto, so doesn't need
   * to dispatch anything
   */
  submitFeedback: noOp,
});

export const MainMenu = connect(mapStateToProps, mapDispatchToProps)(Component);
