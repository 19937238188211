import type { UUID } from "@carescribe/types";
import type { BaseEditor } from "slate";
import type { HistoryEditor } from "slate-history";
import type { ReactEditor } from "slate-react";

import { createEditor as slateCreateEditor } from "slate";
import { withHistory } from "slate-history";
import { withReact } from "slate-react";

import { pipe } from "@carescribe/utilities/src/fp";

type MultiDocumentEditor = BaseEditor & { documentUUID: UUID | null };

export type EditorType = BaseEditor &
  ReactEditor &
  HistoryEditor &
  MultiDocumentEditor;

const withMultiDocument =
  (documentUUID: UUID | null) =>
  <E extends BaseEditor>(editor: E): E & MultiDocumentEditor => {
    (editor as E & MultiDocumentEditor).documentUUID = documentUUID;
    return editor as E & MultiDocumentEditor;
  };

export const createEditor = (documentUUID: UUID | null): EditorType => {
  const withPlugins = pipe(
    withReact,
    withHistory,
    withMultiDocument(documentUUID)
  );
  const baseEditor = slateCreateEditor();
  const editor = withPlugins(baseEditor);
  return editor;
};
