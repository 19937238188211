import type { PreviewableDocument, Document } from "../types";

import { extractText } from "@carescribe/slate/src/utils/extractText";

/**
 * Converts a document to one that can be previewed by its:
 *
 * - title: the first non-empty element in the document
 * - contents: the second non-empty element in the document
 *
 * Both title and content previews are capped at 80 characters.
 */
export const convertToPreviewableDocument = (
  document: Document
): PreviewableDocument => {
  let title: string | null = null;
  let preview: string | null = null;

  for (const child of document.children) {
    const text = extractText(child).trim();

    // we're not interested in empty elements
    if (!text.length) {
      continue;
    }

    if (!title) {
      title = text.slice(0, 80);

      // move to next iteration, as we want preview to be the next
      // element with text
      continue;
    }

    preview = text.slice(0, 80);

    // terminate the loop early as we have everything we need
    break;
  }

  return { id: document.id, title, preview };
};
