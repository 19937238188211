import type { RootState } from "@talktype/store";
import type { AppDispatch } from "@talktype/store/src/types/AppDispatch";
import type { VoiceCommandsSearchProps } from "@talktype/ui/src/VoiceCommands/VoiceCommandsSearch";

import { connect } from "react-redux";

import { setSearchTerm } from "@talktype/listing/src/reducer";
import { selectIsSearching } from "@talktype/listing/src/reducer/selectors/selectIsSearching";
import { selectSearchResultCount } from "@talktype/listing/src/reducer/selectors/selectSearchResultCount";
import { VoiceCommandsSearch as Component } from "@talktype/ui/src/VoiceCommands/VoiceCommandsSearch";

type StateProps = Pick<VoiceCommandsSearchProps, "isSearching" | "resultCount">;
const mapStateToProps = (state: RootState): StateProps => ({
  isSearching: selectIsSearching(state),
  resultCount: selectSearchResultCount(state),
});

type DispatchProps = Pick<VoiceCommandsSearchProps, "onSearch">;
const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  onSearch: (searchTerm) => dispatch(setSearchTerm(searchTerm)),
});

export const VoiceCommandsSearch = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
