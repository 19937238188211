import type { ReactElement } from "react";

export const WindowsMinimiseIcon = (): ReactElement => (
  <svg
    width="10"
    height="2"
    viewBox="0 0 10 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0.625C0 0.279825 0.279825 0 0.625 0H9.375C9.72017 0 10 0.279825 10 0.625C10 0.970175 9.72017 1.25 9.375 1.25H0.625C0.279825 1.25 0 0.970175 0 0.625Z"
      fill="currentcolor"
    />
  </svg>
);
