import type { EditorType } from "../../editor";
import type { UUID } from "@carescribe/types";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { take, put } from "redux-saga/effects";

import { broadcastEditor, requestEditor } from "../actions";

/**
 * Get Editor
 *
 * Returns the currently loaded editor or `null` if no editor is loaded.
 *
 * Optionally, takes in a target `documentUUID` which will return the loaded
 * editor only if it matches the target documentUUID.
 */
export const getEditor = function* ({
  documentUUID,
}: {
  documentUUID: UUID | null;
}): SagaIterator<EditorType | null> {
  yield put(requestEditor());

  const { payload: editor }: SagaReturnType<typeof broadcastEditor> =
    yield take(broadcastEditor);

  if (documentUUID && editor?.documentUUID !== documentUUID) {
    return null;
  }

  return editor;
};
