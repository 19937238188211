import type { CombinedState } from "../types/CombinedState";
import type { ResultTarget } from "@talktype/types";

import { createSelector } from "@reduxjs/toolkit";

import {
  selectDictationMode,
  selectCurrentDocumentId,
} from "@talktype/editor/src/reducers";
import { selectActiveApp } from "@talktype/system/src/reducers/system/selectors/selectActiveApp";

export const selectResultsState = (
  state: CombinedState
): CombinedState["results"] => state.results;

export const selectInProgressResult = createSelector(
  selectResultsState,
  (state) => state.inProgressResult
);

export const selectInProgress = createSelector(
  selectInProgressResult,
  (inProgressResult) => inProgressResult !== null
);

/**
 * Returns the app to assign to a new result based on the current dictation
 * mode.
 *
 * If the dictation mode is "anywhere", the active app is returned.
 * Otherwise, the TalkType app is returned.
 */
export const selectAssignableApp = createSelector(
  selectDictationMode,
  selectActiveApp,
  selectCurrentDocumentId,
  (dictationMode, activeApp, documentUUID): ResultTarget => {
    if (dictationMode === "talktype") {
      /**
       * This should never happen unless something is seriously wrong.
       * There should always be a selected document id available at this point.
       */
      if (documentUUID === null) {
        throw Error("Cannot assign TalkType result to null documentUUID");
      }
      return { name: "TalkType", isSelf: true, documentUUID };
    }

    return { name: activeApp.name, isSelf: false, documentUUID: null };
  }
);
