import type { UUID } from "@carescribe/types";
import type { ReactElement } from "react";

import { memo } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";

import styles from "./libraryRow.module.scss";
import { messages } from "./messages";

export type LibraryRowProps = {
  id: string;
  title: string | null;
  preview: string | null;
  highlight: boolean;
  onChangeDocument: (documentUUID: UUID) => void;
};

export const LibraryRow = memo(
  ({
    id,
    title,
    preview,
    highlight,
    onChangeDocument,
  }: LibraryRowProps): ReactElement => (
    <li className={classNames(styles.listItem, [highlight, styles.highlight])}>
      <label className={styles.container}>
        <input
          onChange={(): void => onChangeDocument(id)}
          className={styles.input}
          value={id}
          type="radio"
          name="document"
        />
        <h3 className={styles.title}>{title ?? messages.untitled}</h3>
        <p className={styles.preview}>{preview ?? messages.noAdditionalText}</p>
      </label>
    </li>
  )
);

LibraryRow.displayName = "LibraryRow";
