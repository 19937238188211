import type { SagaIterator } from "redux-saga";

import { put, takeEvery } from "redux-saga/effects";

import { requestFinaliseDictation } from "@talktype/actions";

import { addDocument, setCurrentDocumentId } from "../../reducers";
import { editorClicked, editorKeyDown } from "../actions";

/**
 * Finalise in-progress results whenever:
 *
 * - editor is clicked
 * - key is pressed
 * - a new document is added
 * - the current document is changed
 */
export const finaliseDictation = function* (): SagaIterator<void> {
  yield takeEvery(
    [editorClicked, editorKeyDown, addDocument, setCurrentDocumentId],
    function* (): SagaIterator<void> {
      yield put(requestFinaliseDictation());
    }
  );
};
