import type { AppDispatch, RootState } from "@talktype/store";
import type { DictationHistoryProps } from "@talktype/ui/src/DictationHistory";

import { connect } from "react-redux";

import { selectDictationHistoryIsEmpty } from "@talktype/dictate-to-app/src/reducers/dictateToApp/selectors";
import { closeDrawer } from "@talktype/preferences";
import { DictationHistory as Component } from "@talktype/ui/src/DictationHistory";
import { DictationHistoryEmpty } from "@talktype/ui/src/DictationHistoryEmpty";
import { getInteractionMethodFromEvent } from "@talktype/utilities/src/getInteractionMethodFromEvent";

import { DictationHistoryPopulated } from "./DictationHistoryPopulated";

const empty = <DictationHistoryEmpty />;
const populated = <DictationHistoryPopulated />;

type StateProps = Omit<DictationHistoryProps, "onClose">;
const mapStateToProps = (state: RootState): StateProps => ({
  isEmpty: selectDictationHistoryIsEmpty(state),
  empty,
  populated,
});

type DispatchProps = Pick<DictationHistoryProps, "onClose">;
const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  onClose: (event) =>
    dispatch(
      closeDrawer({
        interactionMethod: getInteractionMethodFromEvent(event),
        interactionLocation: "close button",
      })
    ),
});

export const DictationHistory = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
