import type { ReactElement, ReactNode } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import styles from "./heading.module.scss";

export type TitleProps = {
  isEmpty: boolean;
  attributes: Record<string, unknown>;
  placeholder: string;
  children: ReactNode;
};

const Placeholder = ({
  placeholder,
}: Pick<TitleProps, "placeholder">): ReactElement => (
  <div
    contentEditable={false}
    aria-hidden={true}
    className={classNames(
      styles.placeholder,
      createSelectorClassName("title-placeholder", "element")
    )}
  >
    {placeholder}
  </div>
);

export const Title = ({
  isEmpty,
  attributes,
  placeholder,
  children,
}: TitleProps): ReactElement => (
  <>
    {isEmpty ? <Placeholder placeholder={placeholder} /> : <></>}

    <h2
      {...attributes}
      {...(isEmpty ? { "aria-label": placeholder } : {})}
      className={classNames(
        styles.heading,
        createSelectorClassName("title", "element")
      )}
    >
      {children}
    </h2>
  </>
);
