import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { all, call, delay, put } from "redux-saga/effects";

import { getAppContext } from "@carescribe/utilities/src/getAppContext";
import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { setChameleonIsEnabled, setDictateToAppIsEnabled } from "../reducers";

const detectDictateToApp = function* (): SagaIterator<void> {
  const context: SagaReturnType<typeof getAppContext> = yield call(
    getAppContext
  );
  yield put(setDictateToAppIsEnabled(context === "desktop"));
};

const detectChameleon = function* (): SagaIterator<void> {
  /**
   * Chameleon may not always be available immediately, so we do a few checks
   * past the initial load to ensure we don't miss it.
   */
  const delayBetweenChecks = 250;
  const numberOfChecks = 4;

  for (let check = 0; check <= numberOfChecks; check++) {
    yield put(setChameleonIsEnabled("chmln" in window));
    yield delay(delayBetweenChecks);
  }
};

export const setUpFeatureFlags = function* (): SagaIterator<void> {
  yield all([detectDictateToApp, detectChameleon].map(retry));
};
