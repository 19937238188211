import type { CustomWordsAddModalProps } from "../CustomWordsAddModal";
import type { CustomWordsEditModalProps } from "../CustomWordsEditModal";
import type { CustomWord, InteractionEvent } from "@talktype/types";
import type { ReactElement } from "react";

import { Plus } from "@phosphor-icons/react";
import { useRef } from "react";

import { useLightDismiss } from "@carescribe/ui";
import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import styles from "./customWords.module.scss";
import { messages } from "./messages";
import { CustomWordsAddModal } from "../CustomWordsAddModal";
import { CustomWordsEmpty } from "../CustomWordsEmpty";
import { CustomWordsPopulated } from "../CustomWordsPopulated";
import { DrawerHeader } from "../DrawerHeader";
import { StandardButton } from "../StandardButton";

export type CustomWordsProps = {
  words: CustomWord[];
  validators: {
    add: CustomWordsAddModalProps["validators"];
    edit: CustomWordsEditModalProps["validators"];
  };
  mutators: {
    add: (partialWord: Omit<CustomWord, "id">) => void;
    delete: (id: string) => void;
    edit: (word: CustomWord) => void;
  };
  onClose: (event: InteractionEvent<HTMLButtonElement>) => void;
};

export const CustomWords = ({
  words,
  validators,
  mutators,
  onClose,
}: CustomWordsProps): ReactElement => {
  const modalRef = useRef<HTMLDialogElement>(null);
  useLightDismiss(modalRef);

  const showModal = (): void => modalRef.current?.showModal();

  const addButton = (
    <StandardButton
      style="default"
      colour="brand"
      hierarchy="primary"
      size="md"
      label={messages.addCustomWord}
      mainIcon={<Plus />}
      elementProps={{
        className: classNames(
          createSelectorClassName("add", "interactive"),
          styles.addButton
        ),
        onClick: showModal,
      }}
    />
  );

  return (
    <>
      <DrawerHeader title={messages.title} onClose={onClose} />
      <div
        className={classNames(
          createSelectorClassName("custom-words", "section"),
          styles.container
        )}
      >
        {words.length > 0 ? (
          <CustomWordsPopulated
            words={words}
            validators={validators.edit}
            handleEdit={mutators.edit}
            handleDelete={mutators.delete}
          >
            {addButton}
          </CustomWordsPopulated>
        ) : (
          <CustomWordsEmpty>{addButton}</CustomWordsEmpty>
        )}
        <CustomWordsAddModal
          modalRef={modalRef}
          validators={validators.add}
          handleAdd={mutators.add}
        />
      </div>
    </>
  );
};
