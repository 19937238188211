import { createSelector } from "@reduxjs/toolkit";

import { selectDictationMode } from "@talktype/editor/src/reducers";
import { selectCompactLayoutIsEnabled } from "@talktype/feature-flags/src/reducers/selectors";
import { selectAlwaysOnTopEnabled } from "@talktype/preferences/src/reducers/preferences";
import { selectUserIsLoggedIn } from "@talktype/user/src/reducer/selectors/selectUserIsLoggedIn";

export const selectPinToFront = createSelector(
  selectUserIsLoggedIn,
  selectCompactLayoutIsEnabled,
  selectDictationMode,
  selectAlwaysOnTopEnabled,
  (
    userIsLoggedIn,
    compactLayoutIsEnabled,
    dictationMode,
    alwaysOnTopEnabled
  ) => {
    /**
     * On legacy versions of TalkType without the compact layout, the
     * `alwaysOnTop` preference was used to determine whether to pin the window
     * to the front regardless of the dictation mode.
     */
    if (!compactLayoutIsEnabled) {
      return userIsLoggedIn && alwaysOnTopEnabled;
    }

    return (
      userIsLoggedIn && (dictationMode === "anywhere" || alwaysOnTopEnabled)
    );
  }
);
