import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";
import type { Node } from "slate";

import { call, put, takeEvery } from "redux-saga/effects";

import { finaliseDocument } from "@talktype/editor/src/reducers";

import { requestFinaliseText, finalisedText } from "./actions";
import { getEditor } from "../utils";

export const isTextNode = (node: Node): boolean =>
  "type" in node && node.type === "text";

/**
 * Finalises the in-progress text whenever a request to finalise is received.
 *
 * When editor is not available:
 * - Finalises the current document directly in state.
 *
 * When editor is available:
 * - Toggles off the inProgress mark for all in-progress nodes.
 */
export const handleFinaliseText = function* (): SagaIterator<void> {
  yield takeEvery(
    requestFinaliseText,
    function* ({ payload: { documentUUID } }) {
      const editor: SagaReturnType<typeof getEditor> = yield call(getEditor, {
        documentUUID,
      });

      if (!editor) {
        yield put(finaliseDocument({ documentUUID }));
        yield put(finalisedText());
        return;
      }

      yield call(
        editor.setNodes,
        { inProgress: false },
        { match: isTextNode, at: [] }
      );
      yield put(finalisedText());
    }
  );
};
