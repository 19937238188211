import type { RootState } from "@talktype/store";
import type { WindowControlsBarProps } from "@talktype/ui/src/WindowControlsBar";

import { connect } from "react-redux";

import { getAppContext } from "@carescribe/utilities/src/getAppContext";

import { selectPlatform } from "@talktype/system/src/reducers/system/selectors/selectPlatform";
import { WindowControlsBar as Component } from "@talktype/ui/src/WindowControlsBar";

import { selectWindowControlsBarHeight } from "../../versioned/3.4.0/selectWindowControlsBarHeight";

type StateProps = Omit<WindowControlsBarProps, "controls" | "colour">;

const mapStateToProps = (state: RootState): StateProps => ({
  context: getAppContext(),
  platform: selectPlatform(state),
  height: selectWindowControlsBarHeight(state),
});

export const WindowControlsBar = connect(mapStateToProps, null)(Component);
