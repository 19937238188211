import type { RootState } from "@talktype/store";
import type { WelcomeProps } from "@talktype/ui/src/Welcome";

import { connect } from "react-redux";

import { selectAuthorizeUrl } from "@carescribe/pkce/src/reducer/selectors/selectAuthorizeUrl";
import { selectRegisterUrl } from "@carescribe/pkce/src/reducer/selectors/selectRegisterUrl";

import { Header } from "@talktype/ui/src/Header";
import { Welcome as Component } from "@talktype/ui/src/Welcome";

import { PWAInstallPrompt } from "./PWAInstallPrompt";
import { WindowControlsBar } from "./WindowControlsBar";
import { WindowsControls } from "./WindowsControls";

const header = (
  <Header style="transparent">
    <WindowControlsBar
      colour="brand"
      controls={<WindowsControls colour="brand" />}
    />
  </Header>
);

const mapStateToProps = (
  state: RootState
): Pick<
  WelcomeProps,
  "header" | "loginLink" | "prompt" | "redemptionLink"
> => ({
  header,
  loginLink: selectAuthorizeUrl(state),
  prompt: <PWAInstallPrompt slideIn />,
  redemptionLink: selectRegisterUrl(state),
});

export const Welcome = connect(mapStateToProps)(Component);
