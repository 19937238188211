import type { AppDispatch, RootState } from "@talktype/store";
import type { LibraryProps } from "@talktype/ui/src/Library";

import { connect } from "react-redux";

import {
  selectCurrentDocumentId,
  selectPreviewableDocuments,
  setCurrentDocumentId,
} from "@talktype/editor/src/reducers";
import { Library as Component } from "@talktype/ui/src/Library";

import { AddDocument } from "./AddDocument";

const children = <AddDocument />;

type StateProps = Pick<
  LibraryProps,
  "children" | "documents" | "currentDocumentId"
>;
const mapStateToProps = (state: RootState): StateProps => ({
  children,
  documents: selectPreviewableDocuments(state),
  currentDocumentId: selectCurrentDocumentId(state),
});

type DispatchProps = Pick<LibraryProps, "onChangeDocument">;
const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  onChangeDocument: (uuid) => dispatch(setCurrentDocumentId(uuid)),
});

export const Library = connect(mapStateToProps, mapDispatchToProps)(Component);
