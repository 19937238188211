import type { TextNode } from "@talktype/types";

import { hasPropertyOfType } from "@carescribe/utilities/src/guards/hasPropertyOfType";
import { isObject } from "@carescribe/utilities/src/guards/isObject";

export const isText = (node: unknown): node is TextNode =>
  isObject(node) &&
  hasPropertyOfType(node, "type", "string") &&
  node.type === "text" &&
  hasPropertyOfType(node, "text", "string") &&
  hasPropertyOfType(node, "inProgress", "boolean");

export const isEmptyText = (node: unknown): boolean =>
  isText(node) && node.text === "";
