import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, takeEvery } from "redux-saga/effects";

import { InteractionMethod, requestTrackEvent } from "@talktype/analytics";
import { getSelectedText } from "@talktype/utilities/src/slate/getSelectedText";

import { editorCut, editorCopy, editorPaste } from "./actions";
import { getEditor } from "./utils";

const isSupportedClipboardEventType = (
  type: string
): type is "cut" | "copy" | "paste" => ["cut", "copy", "paste"].includes(type);

/**
 * Fires off clipboard logging events when the following take place
 * in the editor:
 *
 * - cut
 * - copy
 * - paste
 *
 * A character count is included.
 */
export const trackClipboardEvents = function* (): SagaIterator<void> {
  yield takeEvery(
    [editorCut, editorCopy, editorPaste],
    function* ({ payload: { type, clipboardData } }) {
      // Type guard necessary as ClipboardEvent.type technically typed as string
      if (!isSupportedClipboardEventType(type)) {
        return;
      }

      const editor: SagaReturnType<typeof getEditor> = yield call(getEditor, {
        documentUUID: null,
      });

      if (!editor) {
        return;
      }

      /**
       * For cut/copy events, clipboardData does not return text so
       * we grab it from the selection.
       */
      const text = ["cut", "copy"].includes(type)
        ? getSelectedText(editor)
        : clipboardData.getData("text");

      yield put(
        requestTrackEvent({
          name: "Clipboard Action Performed",
          data: {
            clipboardEventType: type,
            characterCount: text.length,
            interactionMethod: InteractionMethod.hotkey,
          },
        })
      );
    }
  );
};
