import type { Title } from "@talktype/types";

import { hasPropertyOfType } from "@carescribe/utilities/src/guards/hasPropertyOfType";
import { isObject } from "@carescribe/utilities/src/guards/isObject";

import { isLeaf } from "./isLeaf";

export const isTitle = (node: unknown): node is Title =>
  isObject(node) &&
  hasPropertyOfType(node, "type", "string") &&
  node.type === "title" &&
  Array.isArray(node.children) &&
  node.children.every((child) => isLeaf(child));
