import type { ContentProps } from "./Content";
import type { MouseEventHandler, ReactElement } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import { Content } from "./Content";
import styles from "./item.module.scss";

type ButtonProps = ContentProps & {
  id: string;
  shortcut: string | null;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

export const Button = ({
  label,
  Icon,
  id,
  shortcut,
  onClick,
}: ButtonProps): ReactElement => (
  <button
    className={classNames(
      styles.item,
      createSelectorClassName(`main-menu--item--${id}`, "interactive")
    )}
    onClick={onClick}
  >
    <Content label={label} Icon={Icon} />{" "}
    {shortcut ? <kbd className={styles.shortcut}>{shortcut}</kbd> : <></>}
  </button>
);
