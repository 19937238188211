import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, takeEvery } from "redux-saga/effects";
import { Transforms } from "slate";

import { focusEditor } from "@carescribe/slate/src/utils/focusEditor";

import { requestExecuteBackspace, executedBackspace } from "../..";
import { getEditor } from "../utils";

/**
 * Handles requests to execute a backspace action.
 *
 * - Deletes selection if present
 * - Deletes a single character to the left of the cursor
 */
export const manageBackspace = function* (): SagaIterator<void> {
  yield takeEvery(requestExecuteBackspace, function* () {
    const editor: SagaReturnType<typeof getEditor> = yield call(getEditor, {
      documentUUID: null,
    });

    if (editor) {
      // Ensure editor is focused
      yield call(focusEditor, editor);

      yield call(Transforms.delete, editor, {
        reverse: true,
      });
    }

    yield put(executedBackspace());
  });
};
