import type { ReactElement, ReactNode } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";

import styles from "./heading.module.scss";

export type HeadingProps = {
  attributes: Record<string, unknown>;
  children: ReactNode;
};

export const Heading = ({
  attributes,
  children,
}: HeadingProps): ReactElement => (
  <h2 {...attributes} className={classNames(styles.heading)}>
    {children}
  </h2>
);
