import type { Document } from "../types";
import type { SagaIterator } from "redux-saga";

import { put, takeEvery } from "redux-saga/effects";
import { v4 as uuid } from "uuid";

import { action } from "./actions";
import { addDocument } from "../reducers";
import { newDocumentChildren } from "../utils/entities/newDocumentChildren";

export const createNewDocument = action<void, "create-new-document">(
  "create-new-document"
);

const createDocument = (): Document => ({
  id: uuid(),
  children: newDocumentChildren,
  selection: null,
  history: { redos: [], undos: [] },
});

export const setupCreateNewDocument = function* (): SagaIterator<void> {
  yield takeEvery(createNewDocument, function* () {
    const newDocument = createDocument();
    yield put(addDocument(newDocument));
  });
};
