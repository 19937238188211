import type { InteractionEvent } from "@talktype/types";
import type { ReactElement, ReactNode } from "react";

import { DrawerHeader } from "@talktype/ui/src/DrawerHeader";

import { messages } from "./messages";

export type DictationHistoryProps = {
  isEmpty: boolean;
  empty: ReactNode;
  populated: ReactNode;
  onClose: (event: InteractionEvent<HTMLButtonElement>) => void;
};

export const DictationHistory = ({
  isEmpty,
  empty,
  populated,
  onClose,
}: DictationHistoryProps): ReactElement => (
  <>
    <DrawerHeader title={messages.title} onClose={onClose} />
    {isEmpty ? empty : populated}
  </>
);
