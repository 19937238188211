import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { takeEvery, call, put } from "redux-saga/effects";

import { setMark } from "@carescribe/slate/src/utils/setMark";

import { toggleMark } from "@talktype/utilities/src/slate";

import { getSelectionInlineStyleMarks } from "../../utils/getSelectionInlineStyleMarks";
import {
  editorToggledMark,
  requestToggleInlineStyle,
  requestBroadcastInlineStyles,
  broadcastedInlineStyles,
  requestSetInlineStyle,
  inlineStyleSet,
} from "../actions";
import { getEditor } from "../utils";

/**
 * Handles requests to modify editor inline styles:
 *
 * - toggleInlineStyle: toggles the target style at current selection
 * - requestBroadcastInlineStyles: broadcast styles at current selection
 * - setInlineStyle: sets the target style at current selection
 */
export const manageInlineStyling = function* (): SagaIterator<void> {
  yield takeEvery(requestToggleInlineStyle, function* ({ payload: style }) {
    const editor: SagaReturnType<typeof getEditor> = yield call(getEditor, {
      documentUUID: null,
    });

    if (!editor) {
      return;
    }

    const marks: SagaReturnType<typeof getSelectionInlineStyleMarks> =
      yield call(getSelectionInlineStyleMarks, editor);

    yield call(toggleMark, editor, style, marks);
    yield put(editorToggledMark(!marks[style]));
  });

  yield takeEvery(
    requestBroadcastInlineStyles,
    function* (): SagaIterator<void> {
      const editor: SagaReturnType<typeof getEditor> = yield call(getEditor, {
        documentUUID: null,
      });

      if (!editor) {
        return;
      }

      const marks: SagaReturnType<typeof getSelectionInlineStyleMarks> =
        yield call(getSelectionInlineStyleMarks, editor);

      yield put(broadcastedInlineStyles(marks));
    }
  );

  yield takeEvery(
    requestSetInlineStyle,
    function* ({ payload: [style, value] }) {
      const editor: SagaReturnType<typeof getEditor> = yield call(getEditor, {
        documentUUID: null,
      });

      if (!editor) {
        yield put(inlineStyleSet(false));
        return;
      }

      yield call(setMark, editor, style, value);
      yield put(inlineStyleSet(true));
    }
  );
};
