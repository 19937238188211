import type { ShortcutsAddModalProps } from "../ShortcutsAddModal";
import type { ShortcutsEditModalProps } from "../ShortcutsEditModal";
import type { CustomShortcut, InteractionEvent } from "@talktype/types";
import type { ReactElement } from "react";

import { Plus } from "@phosphor-icons/react";
import { useRef } from "react";

import { useLightDismiss } from "@carescribe/ui";
import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import { messages } from "./messages";
import styles from "./shortcuts.module.scss";
import { DrawerHeader } from "../DrawerHeader";
import { ShortcutsAddModal } from "../ShortcutsAddModal";
import { ShortcutsEmpty } from "../ShortcutsEmpty";
import { ShortcutsPopulated } from "../ShortcutsPopulated";
import { StandardButton } from "../StandardButton";

export type ShortcutsProps = {
  shortcuts: CustomShortcut[];
  validators: {
    add: ShortcutsAddModalProps["validators"];
    edit: ShortcutsEditModalProps["validators"];
  };
  mutators: {
    add: (partialShortcut: Omit<CustomShortcut, "id">) => void;
    edit: (shortcut: CustomShortcut) => void;
    delete: (id: string) => void;
  };
  onClose: (event: InteractionEvent<HTMLButtonElement>) => void;
};

export const Shortcuts = ({
  shortcuts,
  validators,
  mutators,
  onClose,
}: ShortcutsProps): ReactElement => {
  const modalRef = useRef<HTMLDialogElement>(null);
  useLightDismiss(modalRef);

  const showModal = (): void => modalRef.current?.showModal();

  const callToAction = (
    <StandardButton
      style="default"
      colour="brand"
      hierarchy="primary"
      size="md"
      label={messages.addShortcut}
      mainIcon={<Plus />}
      elementProps={{
        className: classNames(
          createSelectorClassName("add", "interactive"),
          styles.addButton
        ),
        onClick: showModal,
      }}
    />
  );

  return (
    <>
      <DrawerHeader title={messages.title} onClose={onClose} />
      <div
        className={classNames(
          createSelectorClassName("shortcuts", "section"),
          styles.container
        )}
      >
        {shortcuts.length > 0 ? (
          <ShortcutsPopulated
            shortcuts={shortcuts}
            validators={validators.edit}
            handleEdit={mutators.edit}
            handleDelete={mutators.delete}
          >
            {callToAction}
          </ShortcutsPopulated>
        ) : (
          <ShortcutsEmpty>{callToAction}</ShortcutsEmpty>
        )}

        <ShortcutsAddModal
          modalRef={modalRef}
          validators={validators.add}
          handleAdd={mutators.add}
        />
      </div>
    </>
  );
};
