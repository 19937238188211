import type { ReactElement } from "react";

export const WindowsMaximiseIcon = (): ReactElement => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.125 0C9.09962 0 9.90056 0.743607 9.99142 1.69442L10 1.875V8.125C10 9.09962 9.25639 9.90056 8.30558 9.99142L8.125 10H1.875C0.900376 10 0.0994386 9.25639 0.00858307 8.30558L0 8.125V1.875C0 0.900376 0.743607 0.0994386 1.69442 0.00858307L1.875 0H8.125ZM8.125 1.25H1.875C1.52982 1.25 1.25 1.52982 1.25 1.875V8.125C1.25 8.47017 1.52982 8.75 1.875 8.75H8.125C8.47017 8.75 8.75 8.47017 8.75 8.125V1.875C8.75 1.52982 8.47017 1.25 8.125 1.25Z"
      fill="currentcolor"
    />
  </svg>
);
