import type { ContentProps } from "./Content";
import type { ReactElement } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";

import { Content } from "./Content";
import styles from "./item.module.scss";

export const Header = (contentProps: ContentProps): ReactElement => (
  <div
    data-hj-suppress
    className={classNames(
      styles.item,
      styles.withSeparator,
      styles.noInteraction
    )}
  >
    <Content {...contentProps} />
  </div>
);
