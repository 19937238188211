import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, takeEvery } from "redux-saga/effects";
import { Transforms } from "slate";

import { getRangeOfLastInstance } from "@carescribe/slate/src/utils/range/getRangeOfLastInstance";

import { requestDeletePhrase, deletedPhrase } from "../..";
import { getEditor } from "../utils";

/**
 * Handles requests to delete a given phrase.
 */
export const manageDeletePhrase = function* (): SagaIterator<void> {
  yield takeEvery(requestDeletePhrase, function* ({ payload: target }) {
    const editor: SagaReturnType<typeof getEditor> = yield call(getEditor, {
      documentUUID: null,
    });

    if (!editor) {
      yield put(deletedPhrase(false));
      return;
    }

    // Find last instance of target text
    const matchRange: SagaReturnType<typeof getRangeOfLastInstance> =
      yield call(getRangeOfLastInstance, editor, target);

    // If a match was found, delete it
    if (matchRange) {
      yield call(Transforms.delete, editor, {
        at: matchRange,
      });
      yield put(deletedPhrase(true));
    } else {
      yield put(deletedPhrase(false));
    }
  });
};
