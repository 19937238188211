import type { LibraryRowProps } from "./LibraryRow";
import type { UUID } from "@carescribe/types";
import type { PreviewableDocument } from "@talktype/editor/src/types";
import type { ReactElement, ReactNode } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import styles from "./library.module.scss";
import { LibraryRow } from "./LibraryRow";
import { List } from "../List";

export type LibraryProps = {
  children: ReactNode;
  documents: PreviewableDocument[];
  currentDocumentId: UUID | null;
  onChangeDocument: LibraryRowProps["onChangeDocument"];
};

export const Library = ({
  children,
  documents,
  currentDocumentId,
  onChangeDocument,
}: LibraryProps): ReactElement => (
  <fieldset
    className={classNames(
      styles.container,
      createSelectorClassName("library", "section")
    )}
  >
    {children}
    <List focusable>
      {documents.map((document) => (
        <LibraryRow
          key={document.id}
          id={document.id}
          title={document.title}
          preview={document.preview}
          highlight={document.id === currentDocumentId}
          onChangeDocument={onChangeDocument}
        />
      ))}
    </List>
  </fieldset>
);
