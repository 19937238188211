import type { RootState } from "@talktype/store";
import type { RegularLayoutProps } from "@talktype/ui/src/RegularLayout";

import { connect } from "react-redux";

import { selectLibrarySystemIsEnabled } from "@talktype/feature-flags/src/reducers/selectors";
import { selectDrawerIsOpen } from "@talktype/preferences/src/reducers/";
import { Header } from "@talktype/ui/src/Header";
import { RegularLayout as Component } from "@talktype/ui/src/RegularLayout";

import { AppControls } from "./AppControls";
import { DictationControls } from "./DictationControls";
import { Drawer } from "./Drawer";
import { Editor } from "./Editor";
import { FormattingToolbar } from "./FormattingToolbar";
import { LibraryDrawer } from "./LibraryDrawer";
import { Notifications } from "./Notifications";
import { PWAInstallPrompt } from "./PWAInstallPrompt";
import { WindowControlsBar } from "./WindowControlsBar";
import { WindowsControls } from "./WindowsControls";

const mapStateToProps = (state: RootState): RegularLayoutProps => ({
  drawer: <Drawer />,
  withDrawer: selectDrawerIsOpen(state),
  prompt: <PWAInstallPrompt />,
  windowControlsBar: (
    <WindowControlsBar
      colour="neutral"
      controls={<WindowsControls colour="neutral" />}
    />
  ),
  header: (
    <Header style="default">
      <AppControls />
    </Header>
  ),
  formattingToolbar: <FormattingToolbar />,
  editor: <Editor />,
  dictationControls: <DictationControls />,
  notifications: <Notifications />,

  /**
   * @TODO: remove ternary once library system is released
   */
  library: selectLibrarySystemIsEnabled(state) ? <LibraryDrawer /> : <></>,
});

export const RegularLayout = connect(mapStateToProps)(Component);
