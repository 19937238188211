import type { ReactElement, ReactNode } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";

import styles from "./appControls.module.scss";

export type AppControlsProps = { children: ReactNode; isRounded: boolean };

export const AppControls = ({
  children,
  isRounded,
}: AppControlsProps): ReactElement => (
  <div className={classNames(styles.container, [isRounded, styles.rounded])}>
    {children}
  </div>
);
