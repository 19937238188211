import type { ReactElement, ReactNode } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import styles from "./drawer.module.scss";

export type DrawerProps = {
  label: string;
  children: ReactNode;
  isOpen: boolean;
  width: "regular" | "large";
};

export const Drawer = ({
  label,
  children,
  isOpen,
  width,
}: DrawerProps): ReactElement => (
  <aside
    className={classNames(
      styles.drawer,
      [isOpen, styles.open],
      styles[width],
      createSelectorClassName("drawer", "section")
    )}
    aria-hidden={!isOpen}
    aria-label={label}
  >
    <div className={styles.body}>{children}</div>
  </aside>
);
