import type { Document, PreviewableDocument } from "../types";
import type { UUID } from "@carescribe/types";
import type { PayloadAction } from "@reduxjs/toolkit";
import type {
  Block,
  DictationMode,
  DictationStatus,
  InlineStyles,
} from "@talktype/types";

import { createSelector, createSlice } from "@reduxjs/toolkit";
import { enableMapSet } from "immer";

import { extractText } from "@carescribe/slate";
import { safeStructuredClone } from "@carescribe/utilities/src/safeStructuredClone";

import { defaultBlock } from "@talktype/config/src/defaultBlock";

import { documentIsEmpty } from "./isNewDocument";
import { startDictating } from "../sagas/actions";
import { convertToPreviewableDocument } from "../utils/convertToPreviewableDocument";
import { finaliseChildren } from "../utils/finaliseChildren";

// Enable Immer to support Map and Set
enableMapSet();

export type EditorState = {
  documents: Map<string, Document>;
  currentDocumentId: UUID | null;
  dictationMode: DictationMode;
  dictationStatus: DictationStatus;
  block: Block;
  styles: Record<InlineStyles, boolean>;
};

export type CombinedState = {
  editor: EditorState;
};

const initialState: EditorState = {
  documents: new Map([]),
  currentDocumentId: null,
  dictationMode: "talktype",
  dictationStatus: "inactive",

  block: defaultBlock,

  styles: {
    bold: false,
    italic: false,
    underline: false,
  },
};

const setInitialDocumentReducer = (
  state: EditorState,
  { payload: document }: PayloadAction<Document>
): void => {
  state.currentDocumentId = document.id;
  state.documents.set(document.id, document);
};

const setDocumentReducer = (
  state: EditorState,
  { payload: document }: PayloadAction<Omit<Document, "id">>
): void => {
  const currentDocumentId = state.currentDocumentId;
  if (!currentDocumentId) {
    return;
  }

  const existingDocument = state.documents.get(currentDocumentId);
  if (!existingDocument) {
    return;
  }

  state.documents.set(currentDocumentId, {
    ...existingDocument,
    ...document,
    /**
     * Saving Slate editor's history without creating a deep copy
     * does not tend to go well. Leading to:
     *
     * - Redux errors relating to Immer's auto-freezing
     * See: https://github.com/reduxjs/redux-toolkit/discussions/1189
     *
     * - SerializableCheck errors
     */
    ...("history" in document && {
      history: safeStructuredClone(document.history),
    }),
  });
};

const addDocumentReducer = (
  state: EditorState,
  { payload: document }: PayloadAction<Document>
): void => {
  state.documents.set(document.id, document);
  state.currentDocumentId = document.id;
};

const deleteDocumentReducer = (state: EditorState): void => {
  if (state.documents.size === 1) {
    return;
  }

  const currentDocumentId = state.currentDocumentId;
  if (!currentDocumentId) {
    return;
  }

  state.documents.delete(currentDocumentId);
  state.currentDocumentId = Array.from(state.documents.keys()).at(0) ?? null;
};

/**
 * Normally, the in-progress nodes are finalised directly via the editor.
 * When the editor is not mounted, however, this is not possible.
 * This reducer allows us to finalise the document directly in the
 * state in such cases.
 */
const finaliseDocumentReducer = (
  state: EditorState,
  { payload: { documentUUID } }: PayloadAction<{ documentUUID: UUID | null }>
): void => {
  const currentDocumentUUID = state.currentDocumentId;
  const documentUUIDToFinalise = documentUUID ?? currentDocumentUUID;

  if (!documentUUIDToFinalise) {
    return;
  }

  const document = state.documents.get(documentUUIDToFinalise);
  if (!document) {
    return;
  }

  const children = finaliseChildren(document.children);

  state.documents.set(document.id, { ...document, children });
};

const setCurrentDocumentIdReducer = (
  state: EditorState,
  { payload }: PayloadAction<string>
): void => {
  state.currentDocumentId = payload;
};

const dictationLoadingReducer = (state: EditorState): void => {
  state.dictationStatus = "loading";
};

const dictationStartedReducer = (state: EditorState): void => {
  state.dictationStatus = "active";
};

const dictationStoppedReducer = (state: EditorState): void => {
  state.dictationStatus = "inactive";
};

const setDictationModeReducer = (
  state: EditorState,
  { payload }: { payload: DictationMode }
): void => {
  state.dictationMode = payload;
};

const setActiveStylesReducer = (
  state: EditorState,
  { payload }: PayloadAction<Partial<Record<InlineStyles, boolean>>>
): void => {
  state.styles = { ...state.styles, ...payload };
};

const setActiveBlockReducer = (
  state: EditorState,
  { payload }: PayloadAction<Block>
): void => {
  state.block = payload;
};

const clearActiveStylesReducer = (state: EditorState): void => {
  state.styles = {
    bold: false,
    italic: false,
    underline: false,
  };
};

const slice = createSlice({
  name: "editor",
  initialState,
  reducers: {
    addDocument: addDocumentReducer,
    clearActiveStyles: clearActiveStylesReducer,
    deleteDocument: deleteDocumentReducer,
    dictationStarted: dictationStartedReducer,
    finaliseDocument: finaliseDocumentReducer,
    setActiveBlock: setActiveBlockReducer,
    setActiveStyles: setActiveStylesReducer,
    setCurrentDocumentId: setCurrentDocumentIdReducer,
    setDictationMode: setDictationModeReducer,
    setDocument: setDocumentReducer,
    setInitialDocument: setInitialDocumentReducer,
    stopDictating: dictationStoppedReducer,
  },
  extraReducers: (builder) => {
    builder.addCase(startDictating, dictationLoadingReducer);
  },
});

export const {
  reducer,
  actions: {
    addDocument,
    clearActiveStyles,
    deleteDocument,
    dictationStarted,
    finaliseDocument,
    setActiveBlock,
    setActiveStyles,
    setCurrentDocumentId,
    setDictationMode,
    setDocument,
    setInitialDocument,
    stopDictating,
  },
} = slice;

export const selectDictationStatus = (state: CombinedState): DictationStatus =>
  state.editor.dictationStatus;

export const selectDictationMode = (state: CombinedState): DictationMode =>
  state.editor.dictationMode;

export const selectCurrentDocumentId = (state: CombinedState): string | null =>
  state.editor.currentDocumentId;

export const selectDocuments = (state: CombinedState): Map<string, Document> =>
  state.editor.documents;

export const selectPreviewableDocuments = createSelector(
  [selectDocuments],
  (documents): PreviewableDocument[] =>
    Array.from(documents.values()).map(convertToPreviewableDocument)
);

export const selectCurrentDocument = createSelector(
  [selectCurrentDocumentId, selectDocuments],
  (id, documents) => (id === null ? null : documents.get(id) ?? null)
);

export const selectEditorInitialValue = createSelector(
  [selectCurrentDocument],
  (document) => (document ? document.children : null)
);

export const selectDocumentLength = createSelector(
  selectCurrentDocument,
  (document) => extractText(document).length
);

export const selectDocumentIsEmpty = createSelector(
  [selectCurrentDocument],
  (document) => (document ? documentIsEmpty(document) : true)
);

export const selectDocumentHistory = createSelector(
  [selectCurrentDocument],
  (document) => (document ? document.history : null)
);

export const selectDocumentIsUndoable = createSelector(
  [selectDocumentHistory],
  (history) => (history ? history.undos.length > 0 : false)
);

export const selectDocumentIsRedoable = createSelector(
  [selectDocumentHistory],
  (history) => (history ? history.redos.length > 0 : false)
);

export const selectDocumentSelection = createSelector(
  [selectCurrentDocument],
  (document) => (document ? document.selection : null)
);

export const selectDictating = createSelector(
  [selectDictationStatus],
  (dictationStatus) => dictationStatus === "active"
);

export const editorIsActive = createSelector(
  [selectDocumentIsEmpty, selectDictating],
  (empty, dictating) => !empty || dictating
);

const selectStyles = (state: CombinedState): EditorState["styles"] =>
  state.editor.styles;

export const selectInlineStyleEnabled = createSelector(
  [selectStyles, (_, id: InlineStyles): InlineStyles => id],
  (styles, id): boolean => styles[id]
);

const selectBlock = (state: CombinedState): EditorState["block"] =>
  state.editor.block;

export const selectBlockEnabled = createSelector(
  [selectBlock, (_, target: Block): Block => target],
  (block, target): boolean => {
    if (target === "heading" && block === "title") {
      return true;
    }

    return block === target;
  }
);
