import type { LoginStatus } from "@talktype/user/src/types/LoginStatus";
import type { ReactElement, ReactNode } from "react";

import { Spinner } from "@phosphor-icons/react";

import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";
import { toSeconds } from "@carescribe/utilities/src/timing";

import { LogoIcon } from "@talktype/icons/src/LogoIcon";
import { Waves } from "@talktype/icons/src/Waves";

import { Ctas } from "./Ctas";
import { messages } from "./messages";
import styles from "./welcome.module.scss";
import { IconContainer } from "../IconContainer";
import { ScreenReaderOnly } from "../ScreenReaderOnly";

export type WelcomeProps = {
  prompt: ReactNode;
  header: ReactNode;
  variant: Exclude<LoginStatus, "loggedIn">;
  loginLink: string;
  redemptionLink: string;
};

export const Welcome = ({
  prompt,
  header,
  variant,
  loginLink,
  redemptionLink,
}: WelcomeProps): ReactElement => (
  <main
    className={classNames(
      styles.main,
      createSelectorClassName(`welcome-${variant.toLowerCase()}`, "section")
    )}
  >
    <Waves duration={toSeconds(120)} className={styles.waves} />

    {header}

    {variant === "loading" ? (
      <>
        <div className={styles.loadingContainer}>
          <ScreenReaderOnly tag="h1">{messages.title}</ScreenReaderOnly>
          <Spinner aria-hidden className={styles.loading} />
        </div>
      </>
    ) : (
      <div className={styles.container}>
        <h1>
          <ScreenReaderOnly tag="span">{messages.title}</ScreenReaderOnly>

          <IconContainer className={styles.logo}>
            <LogoIcon />
          </IconContainer>
        </h1>

        <h2
          className={classNames(
            styles.title,
            createSelectorClassName("title", "element")
          )}
        >
          {messages[variant].title}
        </h2>

        {variant === "migrating" && (
          <p
            className={classNames(
              styles.subtitle,
              createSelectorClassName("subtitle", "element")
            )}
          >
            {messages[variant].subtitle}
          </p>
        )}

        <div className={styles.ctas}>
          <Ctas
            variant={variant}
            loginLink={loginLink}
            redemptionLink={redemptionLink}
          />
        </div>
      </div>
    )}

    <div className={styles.prompt}>{prompt}</div>
  </main>
);
