import type { AppContext } from "@carescribe/types";
import type { Platform } from "@carescribe/types/src/Platform";
import type { ReactElement, ReactNode } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";

import styles from "./windowControlsBar.module.scss";

export type WindowControlsBarProps = {
  context: AppContext;
  platform: Platform;
  controls: ReactNode;
  height: "regular" | "large";
  colour: "brand" | "neutral";
};

export const WindowControlsBar = ({
  context,
  platform,
  controls,
  height,
  colour,
}: WindowControlsBarProps): ReactElement => {
  if (context !== "desktop") {
    return <></>;
  }

  return (
    <div
      className={classNames(styles.controlsBar, styles[height], styles[colour])}
    >
      {platform === "win" ? controls : <></>}
    </div>
  );
};
