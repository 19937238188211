import type { ReactElement, ReactNode } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";

import styles from "./list.module.scss";

export type ListProps = { children: ReactNode; focusable?: boolean };

export const List = ({ focusable, children }: ListProps): ReactElement => (
  <ul className={classNames(styles.list, [focusable, styles.focusable])}>
    {children}
  </ul>
);
