/* eslint-disable @typescript-eslint/no-explicit-any */
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, select, takeEvery } from "redux-saga/effects";

import { setDictationMode } from "@talktype/editor/src/reducers";
import {
  setAlwaysOnTop,
  setPreferences,
} from "@talktype/preferences/src/reducers/preferences";
import { setLoginStatus } from "@talktype/user/src/reducer";
import { getIpc } from "@talktype/utilities";

import { selectPinToFront } from "../selectors";

export const setPinToFront = function* (): SagaIterator<void> {
  const pinToFront: SagaReturnType<typeof selectPinToFront> = yield select(
    selectPinToFront
  );

  const ipc: SagaReturnType<typeof getIpc> = yield call(getIpc);
  const method = ipc?.system?.window?.setAlwaysOnTop?.v1;

  if (method) {
    yield call(method, pinToFront);
    return;
  }

  type LegacyAlwaysOnTop =
    | ((args: { alwaysOnTop: "always" | "never" }) => void)
    | undefined;
  const legacyMethod: LegacyAlwaysOnTop = (ipc?.preferences as any)?.onChange
    ?.v1;

  if (legacyMethod) {
    yield call(legacyMethod, { alwaysOnTop: pinToFront ? "always" : "never" });
  }
};

/**
 * Pin Window to Front
 *
 * Recalculates whether to pin the window to the front when:
 *
 * - the user logs in
 * - preferences are updated
 * - the dictation mode changes
 */
export const pinWindowToFront = function* (): SagaIterator<void> {
  yield call(setPinToFront);
  yield takeEvery(
    [setLoginStatus, setPreferences, setAlwaysOnTop, setDictationMode],
    function* () {
      yield call(setPinToFront);
    }
  );
};
