import type { RootState } from "@talktype/store";
import type { DrawerPage } from "@talktype/types";
import type { DrawerProps } from "@talktype/ui/src/Drawer";
import type { ReactNode } from "react";

import { connect } from "react-redux";

import {
  selectDrawerIsOpen,
  selectDrawerPage,
} from "@talktype/preferences/src/reducers/";
import { Drawer as Component } from "@talktype/ui/src/Drawer";

import { CustomWords } from "./CustomWords";
import { DictationHistory } from "./DictationHistory";
import { Shortcuts } from "./Shortcuts";
import { VoiceCommands } from "./VoiceCommands";

const drawerPages: Record<DrawerPage, ReactNode> = {
  voiceCommands: <VoiceCommands />,
  shortcuts: <Shortcuts />,
  customWords: <CustomWords />,
  history: <DictationHistory />,
};

const mapStateToProps = (state: RootState): DrawerProps => {
  const page = selectDrawerPage(state);

  return {
    label: "Resource centre",
    children: drawerPages[page],
    isOpen: selectDrawerIsOpen(state),
    width: "large",
  };
};

export const Drawer = connect(mapStateToProps, null)(Component);
