import type { UUID } from "@carescribe/types";

import { prefixActionCreator } from "@carescribe/utilities/src/saga";

export const action = prefixActionCreator("saga/talktype-editor/results");

export const requestFinaliseText = action<
  { documentUUID: UUID },
  "request-finalise-text"
>("request-finalise-text");

export const finalisedText = action<void, "finalised-text">("finalised-text");
