import type { Void } from "@carescribe/types";

import { connect } from "react-redux";

import { createNewDocument } from "@talktype/editor/src/sagas/createNewDocument";

type Props = { onAdd: Void };
const mapDispatchToProps: Props = {
  onAdd: createNewDocument,
};

export const AddDocument = connect(
  null,
  mapDispatchToProps
)(({ onAdd }: Props) => <button onClick={onAdd}>New Document</button>);
