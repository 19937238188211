import type { AppDispatch, RootState } from "@talktype/store";
import type { CustomWordsProps } from "@talktype/ui/src/CustomWords";

import { connect } from "react-redux";

import { input as addInput } from "@talktype/forms/src/validators/customWords/add/input";
import { input as editInput } from "@talktype/forms/src/validators/customWords/edit/input";
import {
  closeDrawer,
  requestAddCustomWord,
  requestDeleteCustomWord,
  requestEditCustomWord,
  selectCustomWords,
} from "@talktype/preferences";
import { CustomWords as Component } from "@talktype/ui/src/CustomWords";
import { getInteractionMethodFromEvent } from "@talktype/utilities/src/getInteractionMethodFromEvent";

type StateProps = Pick<CustomWordsProps, "words" | "validators">;
const mapStateToProps = (state: RootState): StateProps => ({
  words: selectCustomWords(state),
  validators: {
    add: { input: addInput(state.preferences.customWords) },
    edit: { input: editInput(state.preferences.customWords) },
  },
});

type DispatchProps = Pick<CustomWordsProps, "mutators" | "onClose">;
const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  mutators: {
    add: (partialWord) => dispatch(requestAddCustomWord(partialWord)),
    delete: (id) => dispatch(requestDeleteCustomWord(id)),
    edit: (word) => dispatch(requestEditCustomWord(word)),
  },
  onClose: (event) =>
    dispatch(
      closeDrawer({
        interactionMethod: getInteractionMethodFromEvent(event),
        interactionLocation: "close button",
      })
    ),
});

export const CustomWords = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
