import type { ContentProps } from "./Content";
import type { ReactElement } from "react";

import { ArrowSquareOut } from "@phosphor-icons/react";

import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import { Content } from "./Content";
import styles from "./item.module.scss";
import { IconContainer } from "../../IconContainer";
import { Link as Wrapper } from "../../Link";

type LinkProps = ContentProps & {
  id: string;
  href: string;
};

export const Link = ({ label, Icon, href, id }: LinkProps): ReactElement => (
  <Wrapper
    className={classNames(
      styles.item,
      createSelectorClassName(`main-menu--item--${id}`, "interactive")
    )}
    href={href}
    target="_blank"
    rel="noopener noreferrer"
  >
    <Content label={label} Icon={Icon} />

    <IconContainer className={classNames(styles.icon, styles.linkIndicator)}>
      <ArrowSquareOut />
    </IconContainer>
  </Wrapper>
);
