import type { RootState } from "@talktype/store";
import type { EditorProps } from "@talktype/ui/src/Editor/Editor";
import type { ReactElement } from "react";

import { connect } from "react-redux";

import { selectCurrentDocumentId } from "@talktype/editor/src/reducers";

import { Editor as Component } from "./Editor";
import { ErrorBoundary } from "./ErrorBoundary";

type Props = Pick<EditorProps, "documentUUID">;

const mapStateToProps = (state: RootState): Props => ({
  documentUUID: selectCurrentDocumentId(state),
});

export const Editor = connect(mapStateToProps)(
  ({ documentUUID }: Props): ReactElement => (
    // A new key is passed in with each document change to trigger a re-render
    <ErrorBoundary key={documentUUID}>
      <Component documentUUID={documentUUID} />
    </ErrorBoundary>
  )
);
