import type { AppDispatch } from "@talktype/store/src/types/AppDispatch";
import type { RootState } from "@talktype/store/src/types/RootState";
import type { VoiceCommandsProps } from "@talktype/ui/src/VoiceCommands";

import { connect } from "react-redux";

import { requestTrackEvent } from "@talktype/analytics/src/sagas/actions";
import { selectIsSearching } from "@talktype/listing/src/reducer/selectors/selectIsSearching";
import { selectListingDisplayStructure } from "@talktype/listing/src/reducer/selectors/selectListingDisplayStructure";
import { selectSearchTerm } from "@talktype/listing/src/reducer/selectors/selectSearchTerm";
import { closeDrawer } from "@talktype/preferences";
import { VoiceCommands as Component } from "@talktype/ui/src/VoiceCommands";
import { getInteractionMethodFromEvent } from "@talktype/utilities/src/getInteractionMethodFromEvent";

import { VoiceCommandsSearch } from "./VoiceCommandsSearch";

const searchBar = <VoiceCommandsSearch />;

type StateProps = Pick<
  VoiceCommandsProps,
  "listing" | "isSearching" | "searchTerm" | "searchBar"
>;
const mapStateToProps = (state: RootState): StateProps => ({
  isSearching: selectIsSearching(state),
  listing: selectListingDisplayStructure(state),
  searchTerm: selectSearchTerm(state),
  searchBar,
});

type DispatchProps = Pick<VoiceCommandsProps, "onClickExpander" | "onClose">;
const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  onClickExpander: (data) =>
    dispatch(requestTrackEvent({ name: "Voice Command Listing Viewed", data })),
  onClose: (event) =>
    dispatch(
      closeDrawer({
        interactionMethod: getInteractionMethodFromEvent(event),
        interactionLocation: "close button",
      })
    ),
});

export const VoiceCommands = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
