import type { PopOverButtonProps } from "@carescribe/ui";
import type { ReactElement } from "react";

import { UserIcon } from "@carescribe/ui/src/components/UserIcon";
import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import styles from "./mainMenuTrigger.module.scss";

type MainMenuTriggerProps = PopOverButtonProps & {
  initials: string;
};

export const MainMenuTrigger = ({
  initials,
  ...buttonProps
}: MainMenuTriggerProps): ReactElement => (
  <button
    {...buttonProps}
    className={classNames(
      styles.button,
      createSelectorClassName("main-menu-trigger", "interactive")
    )}
    aria-label={"Main Menu"}
  >
    <UserIcon transparent size="large" initials={initials} />
  </button>
);
