import { StrictMode } from "react";
import { render } from "react-dom";
import { scan } from "react-scan";

import { setUpSegment } from "@carescribe/analytics/src/setUpSegment";

import { Provider } from "@talktype/store";

import { App } from "./components/App";
import { SEGMENT_WRITE_KEY } from "./env";
import { rootSaga } from "./sagas";

import "@talktype/ui/src/styles/index.scss";

if (process.env.NODE_ENV === "development") {
  scan({
    enabled: !("Cypress" in window),
  });
}

if (SEGMENT_WRITE_KEY) {
  setUpSegment(SEGMENT_WRITE_KEY);
}

const root = document.getElementById("root");

if (root instanceof HTMLElement) {
  render(
    <Provider rootSaga={rootSaga}>
      {/**
       * N.B. wrapping the Provider can have unintended effects, such as
       * effects in sagas running twice in development, causing some features
       * to break!
       */}
      <StrictMode>
        <App />
      </StrictMode>
    </Provider>,
    root
  );
}
