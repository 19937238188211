import type { AppControlsProps } from "@talktype/ui/src/AppControls";

import { connect } from "react-redux";

import { getAppContext } from "@carescribe/utilities/src/getAppContext";

import { AppControls as Component } from "@talktype/ui/src/AppControls";

import { MainMenu } from "./MainMenu";
import { QuickNav } from "./QuickNav";

const children = (
  <>
    <MainMenu />
    <QuickNav />
  </>
);

const mapStateToProps = (): Pick<
  AppControlsProps,
  "children" | "isRounded"
> => ({
  children,
  isRounded: getAppContext() === "desktop",
});

export const AppControls = connect(mapStateToProps)(Component);
