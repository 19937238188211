import type { Me } from "@talktype/user/src/types/Me";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { createSelector } from "@reduxjs/toolkit";
import { all, call, put, select } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { selectMe } from "@talktype/user/src/reducer/selectors/selectMe";

import { setLibrarySystemIsEnabled } from "../reducers";

/*
 * This selector should *only* be used inside this saga
 * Should use selectors on the features reducer internally
 */
const selectUserReleaseFlags = createSelector(
  [selectMe],
  (me: Me | null): string[] | null => {
    return me?.releaseToggles ?? null;
  }
);

const isReleased = function* (flag: string): SagaIterator<boolean> {
  const flags: SagaReturnType<typeof selectUserReleaseFlags> = yield select(
    selectUserReleaseFlags
  );

  return flags ? flags.includes(flag) : false;
};

const detectSupportsLibrarySystem = function* (): SagaIterator<void> {
  const isEnabled: SagaReturnType<typeof isReleased> = yield call(
    isReleased,
    "talktype__library_system"
  );
  yield put(setLibrarySystemIsEnabled(isEnabled));
};

export const setUpReleaseFlags = function* (): SagaIterator<void> {
  yield all([detectSupportsLibrarySystem].map(retry));
};
