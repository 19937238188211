import type { DictationToast as DictationToastType } from "@talktype/types";
import type { DictationToastProps } from "@talktype/ui/src/DictationToast";
import type { ReactElement } from "react";

import { Ear, Robot, Check, Warning } from "@phosphor-icons/react";

import { DictationToast as DictationToastComponent } from "@talktype/ui/src/DictationToast";

import { messages } from "./messages";

const getProps = ({
  status,
  text,
}: DictationToastType): Pick<
  DictationToastProps,
  "colour" | "icon" | "title" | "description"
> => {
  switch (status) {
    case "listening": {
      const instruction = [
        { id: "0", content: messages.TalkType, isDataSensitive: false },
      ];

      // Format the instruction text
      text.forEach(({ content, isDataSensitive }, index) => {
        const shouldLowerCase = index === 0;
        const id = (index + 1).toString();
        instruction.push({
          id,
          content: " " + (shouldLowerCase ? content.toLowerCase() : content),
          isDataSensitive,
        });
      });

      const description = { text: instruction, visible: true };

      return {
        colour: "brand",
        icon: <Ear weight="bold" />,
        title: { text: messages.listening, visible: true },
        description,
      };
    }

    case "unrecognised":
      return {
        colour: "brand",
        icon: <Robot weight="bold" />,
        title: { text: messages.unrecognised, visible: false },
        description: {
          text: [
            {
              id: "",
              content: messages.noCommandDetected,
              isDataSensitive: false,
            },
          ],
          visible: true,
        },
      };

    case "unsupported":
      return {
        colour: "destructive",
        icon: <Warning weight="bold" />,
        title: { text: messages.unsupported, visible: false },
        description: {
          text: [
            {
              id: "",
              content: messages.commandIsNotSupportedIn(text),
              isDataSensitive: false,
            },
          ],
          visible: true,
        },
      };

    case "successful":
      return {
        colour: "brand",
        icon: <Check weight="bold" />,
        title: { text: messages.completed, visible: false },
        description: {
          text: [{ id: "", content: text, isDataSensitive: false }],
          visible: true,
        },
      };

    case "failed":
      return {
        colour: "destructive",
        icon: <Warning weight="bold" />,
        title: { text: messages.completed, visible: false },
        description: {
          text: [{ id: "", content: text, isDataSensitive: false }],
          visible: true,
        },
      };
  }
};

export const DictationToastInstance = (
  toast: DictationToastType
): ReactElement => (
  <DictationToastComponent
    hierarchy="secondary"
    onClose={null}
    {...getProps(toast)}
  />
);
