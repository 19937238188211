import type { AppDispatch, RootState } from "@talktype/store";
import type {
  DrawerInfo,
  DrawerPage,
  InteractionEvent,
  QuickNavItem,
} from "@talktype/types";
import type { QuickNavProps } from "@talktype/ui/src/QuickNav";

import { connect } from "react-redux";

import { quickNavItems } from "@talktype/config/src/quickNavItems";
import {
  closeDrawer,
  openDrawer,
  selectDrawer,
} from "@talktype/preferences/src/reducers/";
import { selectIsDictatingToApp } from "@talktype/store/src/selectors/selectIsDictatingToApp";
import { QuickNav as Component } from "@talktype/ui/src/QuickNav";
import { getInteractionMethodFromEvent } from "@talktype/utilities/src/getInteractionMethodFromEvent";

type StateProps = {
  drawer: DrawerInfo;
  isDictatingToApp: boolean;
};

const mapStateToProps = (state: RootState): StateProps => ({
  drawer: selectDrawer(state),
  isDictatingToApp: selectIsDictatingToApp(state),
});

type DispatchProps = {
  onOpenPage: (
    event: InteractionEvent<HTMLButtonElement>,
    id: DrawerPage
  ) => void;
  onClosePage: (event: InteractionEvent<HTMLButtonElement>) => void;
};

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  onOpenPage: (event, id) =>
    dispatch(
      openDrawer({
        drawerPage: id,
        interactionMethod: getInteractionMethodFromEvent(event),
      })
    ),

  onClosePage: (event) =>
    dispatch(
      closeDrawer({
        interactionMethod: getInteractionMethodFromEvent(event),
        interactionLocation: "quick nav",
      })
    ),
});

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps
): Pick<QuickNavProps, "items"> & StateProps => {
  const transformedItems = quickNavItems.map((item): QuickNavItem => {
    const active =
      stateProps.drawer.isOpen && stateProps.drawer.page === item.id;

    const highlight =
      !active && item.id === "history" && stateProps.isDictatingToApp;

    const onClick = active
      ? dispatchProps.onClosePage
      : dispatchProps.onOpenPage;

    return {
      ...item,
      active,
      highlight,
      onClick: (event) => onClick(event, item.id),
    };
  });

  return {
    ...stateProps,
    items: transformedItems,
  };
};

export const QuickNav = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Component);
