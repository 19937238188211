import type { Paragraph } from "@talktype/types";
import type { Descendant } from "slate";

import { headDef } from "@carescribe/utilities/src/fp";
import { hasPropertyOfType } from "@carescribe/utilities/src/guards/hasPropertyOfType";
import { isObject } from "@carescribe/utilities/src/guards/isObject";

import { isLeaf } from "./isLeaf";
import { isEmptyText } from "./isText";

export const isEmptyParagraph = (maybeParagraph: Descendant | null): boolean =>
  isParagraph(maybeParagraph) &&
  isEmptyText(headDef(maybeParagraph.children, null));

export const isParagraph = (node: unknown): node is Paragraph =>
  isObject(node) &&
  hasPropertyOfType(node, "type", "string") &&
  node.type === "paragraph" &&
  Array.isArray(node.children) &&
  node.children.every((child) => isLeaf(child));
