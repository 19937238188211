import type { EditorType } from "../../editor";

import { Transforms } from "slate";

export const insertParagraph = (editor: EditorType): void => {
  Transforms.splitNodes(editor, { always: true });

  /**
   * Ensures new block node is of type `paragraph`
   * (e.g. previously node type may have been `heading`)
   */
  Transforms.setNodes(editor, { type: "paragraph" });
};
