import type { PopOverButtonProps } from "@carescribe/ui";
import type { ReactElement, ReactNode } from "react";

import { usePopover } from "@carescribe/ui";
import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import styles from "./menu.module.scss";
import { ScreenReaderOnly } from "../ScreenReaderOnly";

export type MenuProps = {
  items: ReactNode[];
  renderTrigger: (buttonProps: PopOverButtonProps) => ReactNode;
};

export const Menu = ({ items, renderTrigger }: MenuProps): ReactElement => {
  const { containerProps, buttonProps, popoverProps } = usePopover();

  return (
    <nav {...containerProps} className={styles.container}>
      {/* At least one h1 required on page for accessibility. */}
      <ScreenReaderOnly tag="h1">TalkType</ScreenReaderOnly>

      {renderTrigger(buttonProps)}

      <dialog
        {...popoverProps}
        className={classNames(
          styles.itemsSurface,
          createSelectorClassName("main-menu", "interactive")
        )}
      >
        <ul className={styles.items}>
          {items.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </dialog>
    </nav>
  );
};
