import type { ReactElement } from "react";
import type { RenderElementProps } from "slate-react";

import { assertUnreachable } from "@carescribe/utilities/src/types";

import { Heading } from "../Heading";
import { Title } from "../Heading/Title";
import { Paragraph } from "../Paragraph";

export const RenderElement = ({
  element,
  children,
  attributes,
  isEmpty,
}: RenderElementProps & { isEmpty: boolean }): ReactElement => {
  switch (element.type) {
    case "title":
      return (
        <Title
          isEmpty={isEmpty}
          placeholder={element.placeholder}
          attributes={attributes}
        >
          {children}
        </Title>
      );

    case "heading":
      return <Heading attributes={attributes}>{children}</Heading>;

    case "paragraph":
      return <Paragraph attributes={attributes}>{children}</Paragraph>;

    default:
      return assertUnreachable(element);
  }
};
