import type { Descendant, Editor } from "slate";

import { documentIsEmpty as slateDocumentIsEmpty } from "@carescribe/slate";
import { headDef, indexDef } from "@carescribe/utilities/src/fp";

import { isEmptyParagraph } from "../guards/isParagraph";
import { isEmptyText } from "../guards/isText";
import { isTitle } from "../guards/isTitle";

const isEmptyTitle = (maybeTitle: Descendant | null): boolean =>
  // needs to be a heading
  isTitle(maybeTitle) &&
  // needs to have empty text
  isEmptyText(headDef(maybeTitle.children, null));

const isNewDocument = ({ children }: Pick<Editor, "children">): boolean => {
  if (children.length !== 2) {
    return false;
  }

  return (
    isEmptyTitle(headDef(children, null)) &&
    isEmptyParagraph(indexDef(children, 1, null))
  );
};

export const documentIsEmpty = (document: Pick<Editor, "children">): boolean =>
  isNewDocument(document) || slateDocumentIsEmpty(document);
