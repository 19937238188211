import type { Void } from "@carescribe/types";
import type { Platform } from "@carescribe/types/src/Platform";
import type { MenuProps } from "@talktype/ui/src/Menu";
import type { Me } from "@talktype/user/src/types/Me";
import type { ReactElement, ReactNode } from "react";

import {
  ChatCenteredText,
  Gear,
  Question,
  SignOut,
  UserSquare,
} from "@phosphor-icons/react";
import { useCallback, useEffect, useRef } from "react";

import { useLightDismiss } from "@carescribe/ui";
import { UserIcon } from "@carescribe/ui/src/components/UserIcon";

import { helpSiteUrl } from "@talktype/config/src/helpSiteUrl";
import { Menu } from "@talktype/ui/src/Menu";
import { Header } from "@talktype/ui/src/Menu/Item/Header";

import { messages } from "./messages";
import { Button } from "../Menu/Item/Button";
import { Link } from "../Menu/Item/Link";
import { PreferencesModal } from "../PreferencesModal";

export type MainMenuProps = {
  platform: Platform;
  preferencesFields: ReactNode;
  logOut: Void;
  dashboardUrl: string;
  canSubmitFeedback: boolean;
  submitFeedback: Void;
  user: Me | null;
  renderTrigger: MenuProps["renderTrigger"];
};

export const MainMenu = ({
  platform,
  preferencesFields,
  logOut,
  canSubmitFeedback,
  submitFeedback,
  dashboardUrl,
  user,
  renderTrigger,
}: MainMenuProps): ReactElement => {
  const modalRef = useRef<HTMLDialogElement>(null);
  useLightDismiss(modalRef);

  const showModal = useCallback(
    (): void => modalRef.current?.showModal(),
    [modalRef]
  );

  const items: ReactNode[] = [
    ...(user
      ? [
          <Header
            key="user"
            label={user.fullName}
            Icon={<UserIcon size="small" initials={user.initials} />}
          />,
        ]
      : []),
    <Button
      key="preferences"
      id="preferences"
      label={messages.preferences.label}
      Icon={<Gear />}
      shortcut={messages.preferences.shortcut({ platform })}
      onClick={showModal}
    />,
    <Link
      key="account"
      id="account"
      label={messages.account.label}
      Icon={<UserSquare />}
      href={dashboardUrl}
    />,
    <Link
      key="helpCentre"
      id="helpCentre"
      label={messages.helpCentre.label}
      Icon={<Question />}
      href={helpSiteUrl}
    />,
    ...(canSubmitFeedback
      ? [
          <Button
            key="feedback"
            id="feedback"
            label={messages.feedback.label}
            Icon={<ChatCenteredText />}
            shortcut={null}
            onClick={submitFeedback}
          />,
        ]
      : []),
    <Button
      key="logOut"
      id="logOut"
      label={messages.logOut.label}
      Icon={<SignOut />}
      shortcut={null}
      onClick={logOut}
    />,
  ];

  // MacOS only: open preferences modal on Cmd + ,
  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent): void => {
      if (platform === "mac" && event.metaKey && event.key === ",") {
        event.preventDefault();
        showModal();
      }
    };

    document.addEventListener("keydown", onKeyDown);
    return () => document.removeEventListener("keydown", onKeyDown);
  }, [showModal, platform]);

  return (
    <>
      <Menu renderTrigger={renderTrigger} items={items} />
      <PreferencesModal modalRef={modalRef}>
        {preferencesFields}
      </PreferencesModal>
    </>
  );
};
