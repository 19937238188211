import type { ReactElement } from "react";

export const WindowsCloseIcon = (): ReactElement => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.110739 0.269618L0.183058 0.183058C0.400016 -0.0338996 0.736797 -0.0580062 0.980382 0.110739L1.06694 0.183058L5 4.11625L8.93306 0.183058C9.17714 -0.0610193 9.57286 -0.0610193 9.81694 0.183058C10.061 0.427136 10.061 0.822864 9.81694 1.06694L5.88375 5L9.81694 8.93306C10.0339 9.15002 10.058 9.4868 9.88926 9.73038L9.81694 9.81694C9.59998 10.0339 9.2632 10.058 9.01962 9.88926L8.93306 9.81694L5 5.88375L1.06694 9.81694C0.822864 10.061 0.427136 10.061 0.183058 9.81694C-0.0610193 9.57286 -0.0610193 9.17714 0.183058 8.93306L4.11625 5L0.183058 1.06694C-0.0338996 0.849984 -0.0580062 0.513203 0.110739 0.269618Z"
      fill="currentcolor"
    />
  </svg>
);
