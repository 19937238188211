import type { Document } from "../types";
import type { UUID } from "@carescribe/types";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { uuid4 } from "@sentry/react/node_modules/@sentry/utils";
import { call, put, select } from "redux-saga/effects";

import { selectLibrarySystemIsEnabled } from "@talktype/feature-flags/src/reducers/selectors";

import { selectCurrentDocumentId, setInitialDocument } from "../reducers";
import { createDocument } from "../utils/createDocument";
import { emptyHistory, emptyParagraph } from "../utils/entities";

/**
 * @TODO
 * Remove once library system is released
 */
const $legacyCreateDocument = (uuid: UUID): Document => {
  return {
    children: [emptyParagraph],
    history: emptyHistory,
    selection: null,
    id: uuid,
  };
};

export const setUpInitialState = function* (): SagaIterator<void> {
  const currentDocumentUUID: SagaReturnType<typeof selectCurrentDocumentId> =
    yield select(selectCurrentDocumentId);

  /**
   * Document loaded from indexDB, so don't need to setup
   */
  if (currentDocumentUUID) {
    return;
  }

  const librarySystemEnabled: SagaReturnType<
    typeof selectLibrarySystemIsEnabled
  > = yield select(selectLibrarySystemIsEnabled);

  const uuid: SagaReturnType<typeof uuid4> = yield call(uuid4);
  const document = librarySystemEnabled
    ? createDocument({ id: uuid })
    : $legacyCreateDocument(uuid);
  yield put(setInitialDocument(document));
};
